body {
  margin: 0;
  font-family: 'Montserrat';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.virtual__mirror_container * {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.virtual__mirror_container {
  width: 750px;
  height: 630px;
  margin: 0 auto;
  background: white;
  border-radius: 15px;
}

.flex__mirror_container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.container__header {
  display: flex;
  height: 415px;
}

.photo__container {
  position: relative;
  width: 444px;
}
.d {
  position: absolute;
}
.webcam__screen {
  border-radius: 10px;
  object-fit: cover;
  height: 100%;
  width: 444px;
}
.webcam__container {
  overflow: hidden;
  width: 444px;
  height: 100%;
}

.rightbar {
  width: 300px;
}

.glasses__title {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #0f0f0f;
  margin-left: 23px;
  margin-top: 16px;
}

.glasses__img {
  max-width: 270px;
  margin-left: 8px;
}

.glasses__btn {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #000000;
  border-radius: 50px;
  padding: 10px 25px;
  border: none;
  cursor: pointer;
  color: white;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.4px;
  color: #ffffff;
  margin-left: 57px;
  margin-top: 15px;
}

.glasses__about {
}
.glasses__about_title {
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #0f0f0f;
  margin-left: 23px;
  margin-top: 52px;
}
.glasses__about_description {
  font-size: 12px;

  font-family: Open Sans;
  line-height: 17px;
  letter-spacing: 0.5px;
  margin-left: 26px;
  margin-top: 5px;
  max-width: 240px;
  color: #2d2d2d;
}

.glasse__slider {
  width: 199.02px;
  height: 99.51px;
}

.editor {
  padding-left: 20px;
}
.editor__back {
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  cursor: pointer;
  margin-top: 18px;
}

.editor__back_arrow {
  margin-right: 5px;
}
.editor__title {
  margin-top: 28px;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: #0f0f0f;
}
.editor__instructions {
}
.editor__instruction {
  margin-top: 30px;
  font-family: Open Sans;
}
.editor__instruction_title {
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: #000000;
}
.editor__instruction_title strong {
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  color: #000000;
  margin-right: 5px;
}

.editor__pd {
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.228571px;
  color: #89959c;
  background: #ffffff;
  border: 1px solid #ada7a7;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 13px 14px 13px 20px;
  max-width: 75px;
  height: 40px;
  margin-top: 16px;
  margin-left: 39px;
}
.editor__instruction_pd_img {
  margin-left: 5px;
}

.editor__instruction_subtitle {
  margin-top: 30px;
  font-family: Montserrat;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}

.editor__input_range {
  width: 250px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.editor__input_range::-webkit-slider-runnable-track {
  height: 6px;
  background: #dedede;
  border-radius: 2.5px;
}
.editor__input_range::-webkit-slider-thumb {
  background: #000000;
  box-shadow: 1px 1px 1px #000000;
  border: 1px solid #000000;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -7px;
}

.editor__input_range::-moz-range-track {
  height: 6px;
  background: #dedede;
  border-radius: 2.5px;
}
.editor__input_range::-moz-range-thumb {
  background: #000000;
  box-shadow: 1px 1px 1px #000000;
  border: 1px solid #000000;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  cursor: pointer;
  -moz-appearance: none;
  margin-top: -7px;
}

input[type='range']::-moz-range-progress {
  background-color: #000000;
  height: 6px;
}

.slider__container {
  display: flex;
}

.webcam__confirm_container h3 {
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: #0f0f0f;
}
.webcam__confirm_container h4 {
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: #0f0f0f;
  margin-top: 10px;
}

.webcam__confirm_container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 337px;
  text-align: center;
}

.mark {
  width: 30px;
  height: 30px;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 45%;
  cursor: move;
  z-index: 100;
}

.redMark {
  position: absolute;
  width: 150px;
  height: 100px;
  background: red;
  transform: translate(-50%,-50%);
}

.mark::after {
  content: '';
  height: 30px;
  width: 3px;
  background: #f22a42;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.mark::before {
  position: absolute;
  content: '';
  height: 3px;
  width: 30px;
  top: 50%;
  transform: translateY(-50%);
  background: #f22a42;
}

.left__mark {
}
.right__mark {
}
